<template>
  <el-form ref="form" :model="user" :rules="rules" label-width="80px">
    <el-form-item label="用户昵称" prop="nameReal">
      <el-input v-model="user.nameReal" maxlength="30" />
    </el-form-item> 
    <el-form-item label="手机号码" prop="phoneNumber">
      <el-input v-model="user.phoneNumber" maxlength="11" />
    </el-form-item>
    <!-- <el-form-item label="邮箱" prop="email">
      <el-input v-model="user.email" maxlength="50" />
    </el-form-item> -->
    <!-- <el-form-item label="性别">
      <el-radio-group v-model="user.sex">
        <el-radio label="0">男</el-radio>
        <el-radio label="1">女</el-radio>
      </el-radio-group>
    </el-form-item> -->
    <el-form-item>
      <el-button type="primary" size="mini" @click="submit">保存</el-button>
      <el-button type="danger" size="mini" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// import { updateUserProfile } from "@/api/system/user";
import { adminUserUpdateApi, adminUserDetailApi } from '@/api/adminUser'
export default {
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      // 表单校验
      rules: {
        nameReal: [
          { required: true, message: "用户昵称不能为空", trigger: "blur" }
        ],
        // email: [
        //   { required: true, message: "邮箱地址不能为空", trigger: "blur" },
        //   {
        //     type: "email",
        //     message: "请输入正确的邮箱地址",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        phoneNumber: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    console.log('user', this.user);
    // this.user = localStorage.getItem('adminUser')
  },
  methods: {
    submit() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          console.log('提交', this.user);
          var user = {
            groupId: this.user.groupId,
            id: this.user.id,
            name: this.user.name,
            nameReal: this.user.nameReal,
            phoneNumber: this.user.phoneNumber,
            status: this.user.status,
            type: this.user.type,
          }
          var res = await adminUserUpdateApi(user)
          if(res.code === 200){
            this.$modal.msgSuccess("修改成功");
            this.getInfoFun()
          }else{
            this.$modal.msgError('修改失败，请到管理用户里面修改')
          }
         
          // updateUserProfile(this.user).then(response => {
          //   console.log('response', response);
          //   this.$modal.msgSuccess("修改成功");
          // });
        }
      });
    },
    // 获取用户信息
    async getInfoFun(){
      var params = {
        id:this.user.id
      }
      var res = await adminUserDetailApi(params)
      localStorage.setItem('adminUser', JSON.stringify(res))
    },
    close() {
      this.$tab.closePage();
    }
  }
};
</script>
