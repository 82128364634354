import request from '@/utils/request.js'

// 获取管理用户列表
export function adminUserListApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/admin/user',
    method: 'post',
    data: data
  })
}

// 新增管理用户
export function adminUserAddApi(data) {
  return request({
    url: '/doftec/admin-user/admin/create',
    method: 'post',
    data: data
  })
}

// 更新管理用户
export function adminUserUpdateApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/admin/update.u',
    method: 'post',
    data: data
  })
}

// 删除管理用户 ids:[]  menuType: 操作状态：delete 删除 push 推送 download 下载
export function adminUserDeleteApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/admin/action',
    method: 'post',
    data: data
  })
}


// 获取管理用户详情
export function adminUserDetailApi(params) {
  return request({
    url: '/doftec/admin-user/admin/info',
    method: 'get',
    params: params
  })
}

// 获取角色ID 和名称的map
export function rolesListApi(params) {
  return request({
    url: '/doftec/admin-user/roles',
    method: 'get',
    params: params
  })
}